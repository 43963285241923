import {StoreComponent} from '../store/store.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PrivateComponent} from './private.component';
import {BudgetDetailComponent, BudgetListComponent} from '../budget';
import {TimereportComponent} from '../timereport';
import {OrderAddComponent, OrderListComponent, OrderViewComponent} from '../order';
import {InvoicesComponent} from '../invoices/invoices.component';
import {InvoicesCosmoComponent} from '../invoices/invoices-cosmo/invoices-cosmo.component';
import {InvoicesCosmoGuard} from '../invoices/invoices-cosmo/guards/invoices-cosmo.guard';
import {DdtComponent} from '../ddt/ddt.component';
import {AuthGuard} from '../services/auth-guard.service';
import {InternalProjectComponent} from '../internal-project/internal-project.component';
import {BudgetGuard} from '../budget/guards/budget.guard';
import {LaserCutListComponent} from '../laser-cut/laser-cut-list.component';
import {LaserCutGuard} from '../laser-cut/guards/laser-cut.guard';
import {MaterialsListComponent} from '../balances/materials-list.component';
import {BalancesGuard} from '../balances/balances.guard';
import {ActivitiesListComponent} from '../balances/activities-list.component';
import {BalanceGraphComponent} from '../balances/balance-graph.component';
import {UsersAddComponent, UsersListComponent} from '../users';
import {UsersGuard} from '../users/guards/users.guard';
import {SummaryComponent} from '../balances/summaryBalance/summary-component';
import {SuperadminComponent} from '../superadmin/superadmin.component';
import {StatcollectorComponent} from '../statcollector/statcollector.component';
import {StatcollectorViewComponent} from '../statcollector/statcollector-view/statcollector-view.component';
import {WorkersSummaryComponent} from '../workers-summary/workers-summary.component';
import {StatCollectorGuard} from '../statcollector/guards/statcollector.guard';
import {WorkSummaryGuard} from '../workers-summary/guards/worksummary.guard';
import {OrderGuard} from '../order/guards/order.guard';
import {TimeReportGuard} from '../timereport/timereport.guard';
import {InvoiceGuard} from '../invoices/invoice.guard';
import {TimereportEditGuard} from '../timereport/timereport-edit.guard';
import {AdminComponent} from '../admin/admin.component';
import {AdminGuard} from '../admin/guards/admin.guard';



const privateRoutes: Routes = [
    {
        path: '',
        component: PrivateComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        children: [
            {
                path: '',
                children: [
                    {path: '', redirectTo: '/home', pathMatch: 'full'},
                    {path: 'budget', component: BudgetListComponent, canActivate: [BudgetGuard]},
                    {path: 'budget/:id', component: BudgetDetailComponent, canActivate: [BudgetGuard]},
                    {path: 'timereport', component: TimereportComponent, canActivate: [TimeReportGuard]},
                    {
                        path: 'timereport/:id',
                        component: TimereportComponent,
                        canActivate: [TimeReportGuard, TimereportEditGuard]
                    },
                    {path: 'reportactivities', component: StatcollectorComponent, canActivate: [StatCollectorGuard]},
                    {
                        path: 'reportactivities/dashboard',
                        component: StatcollectorViewComponent,
                        canActivate: [StatCollectorGuard]
                    },
                    {path: 'order', component: OrderListComponent, canActivate: [OrderGuard]},
                    {path: 'order/add', component: OrderAddComponent, canActivate: [OrderGuard]},
                    {path: 'order/add/:id', component: OrderAddComponent, canActivate: [OrderGuard]},
                    {path: 'order/view/:id', component: OrderViewComponent, canActivate: [OrderGuard]},
                    {path: 'invoices', component: InvoicesComponent, canActivate: [InvoiceGuard]},
                    {path: 'invoices/:id', component: InvoicesComponent, canActivate: [InvoiceGuard]},
                    {
                        path: 'invoices/:number/:orderId/:date',
                        component: InvoicesComponent,
                        canActivate: [InvoiceGuard]
                    },
                    {path: 'invoices-cosmo', component: InvoicesCosmoComponent, canActivate: [InvoicesCosmoGuard]},
                    {path: 'invoices-cosmo/:id', component: InvoicesCosmoComponent, canActivate: [InvoicesCosmoGuard]},
                    {path: 'ddt', component: DdtComponent, canActivate: [InvoiceGuard]},
                    {path: 'ddt/:id', component: DdtComponent, canActivate: [InvoiceGuard]},
                    {path: 'ddt/:number/:orderId/:date', component: DdtComponent, canActivate: [InvoiceGuard]},
                    {path: 'internalproject', component: InternalProjectComponent},
                    {path: 'internalproject/:id', component: InternalProjectComponent},
                    {path: 'lasercut', component: LaserCutListComponent, canActivate: [LaserCutGuard]},
                    {
                        path: 'balances/materials/:budgetId',
                        component: MaterialsListComponent,
                        canActivate: [BalancesGuard]
                    },
                    {
                        path: 'balances/activities/:budgetId',
                        component: ActivitiesListComponent,
                        canActivate: [BalancesGuard]
                    },
                    {
                        path: 'balances/summary/:budgetId',
                        component: SummaryComponent,
                        canActivate: [BalancesGuard]
                    },
                    {path: 'balances/graph', component: BalanceGraphComponent, canActivate: [BalancesGuard]},
                    {path: 'users', component: UsersListComponent, canActivate: [UsersGuard]},
                    {path: 'users/add', component: UsersAddComponent, canActivate: [UsersGuard]},
                    {path: 'users/add/:id', component: UsersAddComponent, canActivate: [UsersGuard]},
                    {path: 'workSummary', component: WorkersSummaryComponent, canActivate: [WorkSummaryGuard]},
                    {path: 'store', component: StoreComponent},
                    {path: 'superadmin', component: SuperadminComponent, canActivate: [UsersGuard]},
                    {path: 'admin', component: AdminComponent, canActivate: [AdminGuard]}
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(privateRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class PrivateRoutingModule {
}
