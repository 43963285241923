import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BudgetListComponent} from './budget-list.component';
import {BudgetDetailComponent} from './budget-detail.component';
import {MaterialDialogComponent, SubBudgetComponent} from './sub-budget.component';
import {MaterialModule} from '../material.module';
import {SubBudgetFormComponent} from './components/sub-budget-form.component';
import {MaterialFormComponent} from './components/material-form.component';
import {MarginFormComponentV2} from './components/margin-form/v2/margin-form-component-v2.component';
import {SharedModule} from '../shared/shared.module';
import {AssemblyCostsFormComponent} from './components/assembly-costs/assemblyCosts-form.component';
import {ProductionCostsFormComponentV2} from './components/production-costs-form/v2/production-costs-form-component-v2.component';
import {RouterModule} from '@angular/router';
import {BudgetGuard} from './guards/budget.guard';
import {SummaryChartComponent} from './components/summary-chart/summary-chart.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {SubBudgetResumeComponent} from './sub-budget-resume/sub-budget-resume.component';
import {OpenProjectDialogComponent} from './open-project-dialog/open-project-dialog.component';
import {ChangeFinalofferDialogComponent} from './change-finaloffer-dialog/change-finaloffer-dialog.component';
import {BudgetListTableComponent} from './budget-list-table/budget-list-table.component';
import {SplittedCostsTableComponentV2} from './components/splitted-costs-table/v2/splitted-costs-table-component-v2.component';
import {ContactPickerDialogComponent} from './contact-picker-dialog/contact-picker-dialog.component';
import {ArchiveProjectDialogComponent} from './archive-project-dialog/archive-project-dialog.component';
import {DocumentEditorDialogComponent} from './doc-editor-dialog/doc-editor-dialog.component';
import {MarginFormComponentV1} from './components/margin-form/v1/margin-form-component-v1.component';
import {ProductionCostsFormComponentV1} from './components/production-costs-form/v1/production-costs-form-component-v1.component';
import {SplittedCostsTableComponentV1} from './components/splitted-costs-table/v1/splitted-costs-table-component-v1.component';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxChartsModule
    ],
    exports: [MaterialDialogComponent],
    declarations: [
        BudgetListComponent,
        BudgetDetailComponent,
        SubBudgetComponent,
        SubBudgetFormComponent,
        ContactPickerDialogComponent,
        OpenProjectDialogComponent,
        ArchiveProjectDialogComponent,
        DocumentEditorDialogComponent,
        MaterialFormComponent,
        MaterialDialogComponent,
        MarginFormComponentV2,
        MarginFormComponentV1,
        AssemblyCostsFormComponent,
        ProductionCostsFormComponentV1,
        ProductionCostsFormComponentV2,
        SummaryChartComponent,
        SubBudgetResumeComponent,
        ChangeFinalofferDialogComponent,
        BudgetListTableComponent,
        SplittedCostsTableComponentV1,
        SplittedCostsTableComponentV2
    ],
    entryComponents: [ContactPickerDialogComponent,
        DocumentEditorDialogComponent,
        OpenProjectDialogComponent,
        ArchiveProjectDialogComponent,
        MaterialDialogComponent,
        ChangeFinalofferDialogComponent], // https://angular.io/guide/ngmodule-faq#why-does-angular-need-entrycomponents
    providers: [BudgetGuard]
})
export class BudgetModule {
}
