import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Budget, GeneratedBy, Material, SplittedCostTableElement} from '../../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../../services/data.service';
import {BudgetModel} from '../../../models/budget-model';
import {Subject} from 'rxjs';
import {SubBudgetModel} from '../../../models/sub-budget';
import {AuthService} from '../../../../services/auth.service';



@Component({
    selector: 'app-production-costs-form-v1',
    templateUrl: './production-costs-form-component-v1.component.html',
    styleUrls: ['./production-costs-form-component-v1.component.scss']
})
export class ProductionCostsFormComponentV1 implements OnInit, OnDestroy {

    public operationInProgress = false;
    @Output() changeSelectedTab = new EventEmitter<string>();
    @Output() updateCostSummary = new EventEmitter<string>();
    public productionCostsForm: FormGroup;
    public exchangeRateHint: string;
    private unsubscribe$ = new Subject();

    constructor(private dataService: DataService, private fb: FormBuilder, private auth: AuthService) {
    }

    private _budget: Budget;

    get isExport() {
        if (!this._budget) return false;
        if (this._budget.export) return true;
        return false;
    }

    @Input() set budget(b: Budget) {
        this._budget = b;
        if (this.productionCostsForm) {
            this.productionCostsForm.patchValue({
                exchangeRate: this.isExport ? this._budget.export.exchangeRate : undefined
            }, {
                emitEvent: false
            });
        }
    }

    public _authorizedToModify = false;

    get authorizedToModify() {
        return this._authorizedToModify;
    }

    @Input() set authorizedToModify(a: boolean) {
        this._authorizedToModify = a;
    }

    public _splittedCostsRelative: SplittedCostTableElement[] = [];

    @Input() set splittedCostsRelative(a: any[]) {
        if (a) {
            this._splittedCostsRelative = a;
        } else {
            this._splittedCostsRelative = [];
        }
    }

    public _splittedCosts: SplittedCostTableElement[] = [];

    @Input() set splittedCosts(a: any[]) {
        if (a) {
            this._splittedCosts = a;
        } else {
            this._splittedCosts = [];
        }
    }

    ngOnInit() {

        this.productionCostsForm = this.fb.group({
            exchangeRate: [this._budget.export ? this._budget.export.exchangeRate : undefined, Validators.min(0)],
            design: [0, Validators.min(0)],
            shipping: [0, Validators.min(0)]
        });

        this.listenForSubBudgetMaterials();

        if (!BudgetModel.isEditable(this._budget, this.auth.currentUser) && !this.authorizedToModify) {
            this.productionCostsForm.disable();
        } else {
            this.productionCostsForm.enable();
        }

        /**
         * Auto save / commit enabled
         */
        this.productionCostsForm.valueChanges
            .pipe(debounceTime(1000),
                takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                await this.submit();
            });
    }

    public listenForSubBudgetMaterials() {

        this.dataService.getSBMaterial(this._budget.id, 'AUTO_BUDGET', 'design')
            .pipe(
                filter((mat) => mat != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((mat: Material) => {
                this.productionCostsForm.patchValue({
                        design: mat.costEach
                    },
                    {
                        emitEvent: false
                    });
            });

        this.dataService.getSBMaterial(this._budget.id, 'AUTO_BUDGET', 'shipping')
            .pipe(
                filter((mat) => mat != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((mat: Material) => {
                this.productionCostsForm.patchValue({
                        shipping: mat.costEach
                    },
                    {
                        emitEvent: false
                    });
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public refreshExchangeRate = () => {
        this.dataService.getExchangeRate('USD')
            .subscribe((r) => {
                this.exchangeRateHint = 'Rate update from https://fixer.io';
                this.productionCostsForm.patchValue({
                    exchangeRate: r
                });

            });
    }

    async submit() {

        try {
            this.operationInProgress = true;
            const batch = this.dataService.getBatch();

            if (this.isExport) {
                const exportData = this._budget.export ? this._budget.export : {};
                exportData.exchangeRate = this.productionCostsForm.value.exchangeRate;

                await this.dataService.updateSubBudget(this._budget.id, 'AUTO_BUDGET',
                    new SubBudgetModel('Overseas', GeneratedBy.Export, 'AUTO_BUDGET', false), batch);

                const exportDesign = this.productionCostsForm.value.design;
                await this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'design', {
                    description: 'Overseas Design & Project Management',
                    category: 'IUS',
                    costEach: exportDesign,
                    quantity: 1,
                    unit: 'pieces'
                }, batch);

                const exportShipping = this.productionCostsForm.value.shipping;
                await this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'shipping', {
                    description: 'Overseas Shipping',
                    category: 'SPE',
                    costEach: exportShipping,
                    quantity: 1,
                    unit: 'pieces'
                }, batch);

            }
            console.log('ProductionForm - Budget patched, force updating costSummary..', this._budget.id);
        } catch (err) {
            console.log(err);
        } finally {
            this.operationInProgress = false;
            this.updateCostSummary.emit(this._budget.id);
            console.log('ProductionForm - cost summary force updated for', this._budget.id);
        }

    }

    changeTab(label: string) {
        this.changeSelectedTab.emit(label);
    }
}

