import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../services/data.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatInput} from '@angular/material/input';
import {BehaviorSubject, Subject} from 'rxjs';
import * as moment from 'moment-timezone';
import {takeUntil} from 'rxjs/operators';



@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject();
    loading = false;
    reportFiles = [];
    reportsColumns = ['createdAt', 'endAt', 'status', 'fileReference', 'type', 'details'];

    @ViewChild('oreCommesseFromDate', {
        read: MatInput
    }) oreCommesseFromDate;
    @ViewChild('oreCommesseToDate', {
        read: MatInput
    }) oreCommesseToDate;
    public oreCommesseFromDateSubject = new BehaviorSubject<Date>(moment().subtract(1, 'year').toDate());
    public oreCommesseToDateSubject = new BehaviorSubject<Date>(moment().toDate());

    constructor(private dataService: DataService,
                private _snackbar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.dataService.listReportingFiles()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
                this.reportFiles = result;
            });
    }

    generateOreCommesseReport() {
        const from = moment(this.oreCommesseFromDate.value).format('YYYY-MM-DD');
        const to = moment(this.oreCommesseToDate.value).format('YYYY-MM-DD');

        this.loading = true;
        console.log(`generateOreCommesseReport from ${from} to ${to}`);
        this.dataService.generateReportOreCommesse(from, to)
            .subscribe(() => {
                this.loading = false;
                this._snackbar.open(`Report generation started for ${from} - ${to}. PLease check results in few minutes..`,
                    'close',
                    {duration: 6000});
            }, error => {
                this.loading = false;
                console.log(error);
                this._snackbar.open(`Something went wrong during report generation..`,
                    'close',
                    {duration: 6000});
            });
    }

    openOreCommesseLink(id: string) {
        this.loading = true;

        this.dataService.getReportDownloadLink(id)
            .subscribe(result => {
                this.loading = false;
                if (result && result.url) {
                    window.location.href = result.url + '&response-content-disposition=attachment;';
                }
            }, error => {
                this.loading = false;
                console.log(error);
                this._snackbar.open(`Something went wrong..`,
                    'close',
                    {duration: 6000});
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
