import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialsListComponent} from './materials-list.component';
import {BalancesGuard} from './balances.guard';
import {MaterialModule} from '../material.module';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {ActivitiesListComponent} from './activities-list.component';
import {BalanceGraphComponent} from './balance-graph.component';
import {SummaryComponent} from './summaryBalance/summary-component';
import {MaterialsBalanceTableComponent} from './components/materials-balance-table/materials-balance-table.component';
import {ActivityBalanceTableComponent} from './components/activity-balance-table/activity-balance-table.component';
import {SummaryBalanceComponentV2} from './summaryBalance/v2/summary-balance-component-v2.component';
import {SummaryBalanceComponentV1} from './summaryBalance/v1/summary-balance-component-v1.component';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        RouterModule
    ],
    declarations: [
        MaterialsListComponent,
        ActivitiesListComponent,
        BalanceGraphComponent,
        SummaryComponent,
        SummaryBalanceComponentV1,
        SummaryBalanceComponentV2,
        MaterialsBalanceTableComponent,
        ActivityBalanceTableComponent
    ],
    providers: [BalancesGuard]
})
export class BalancesModule {
}
