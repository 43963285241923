import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {DataService} from '../../services/data.service';
import {Budget} from '../../budget/interfaces';
import {SummaryBalanceWrapper} from '../../budget/interfaces/summaryBalance';



@Component({
    selector: 'app-summary-component',
    templateUrl: './summary-component.html',
    styleUrls: ['./summary-component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {

    public budgetId: string;
    public budget$: Observable<Budget>;
    public summaryBalance$: ReplaySubject<SummaryBalanceWrapper> = new ReplaySubject(1);
    private unsubscribe$ = new Subject();

    constructor(private dataService: DataService, private route: ActivatedRoute) {
    }


    ngOnInit() {

        this.budget$ = this.route.paramMap.pipe(
            switchMap((params) => {
                const budgetId = params.get('budgetId');
                return this.dataService.getSimpleBudget(budgetId);
            }),
            takeUntil(this.unsubscribe$));

        this.route.paramMap.pipe(
            switchMap((params) => {
                this.budgetId = params.get('budgetId');
                return this.dataService.getBudgetSummaryBalance(this.budgetId);
            }))
            .pipe(takeUntil(this.unsubscribe$), tap(s => console.log(s)))
            .subscribe(this.summaryBalance$);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    compare(a: number, b: number) {
        return a > b;
    }
}
