import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Budget, MarginV2} from '../../../interfaces';
import {BudgetModel} from '../../../models/budget-model';
import {AuthService} from '../../../../services/auth.service';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';



@Component({
    selector: 'app-margin-form-v2',
    templateUrl: './margin-form-component-v2.component.html',
    styleUrls: ['./margin-form-component-v2.component.scss']
})
export class MarginFormComponentV2 implements OnInit, OnDestroy {

    public formUpdating = false;
    @Input() kind: 'ITA' | 'USA' = 'ITA';
    public marginForm: FormGroup;
    private unsubscribe$ = new Subject();

    @Output() updateCostSummary = new EventEmitter<string>();

    constructor(private dataService: DataService, private fb: FormBuilder, private auth: AuthService) {
    }

    private _budget: Budget;

    get budget() {
        return this._budget;
    }

    @Input() set budget(b: Budget) {
        this._budget = b;

        if (this.marginForm) {
            this.patchForm();
        } else {
            console.log('Margin form not ready yet');
        }
    }

    public _authorizedToModify = false;

    public get authorizedToModify() {
        return this._authorizedToModify;
    }

    @Input()
    public set authorizedToModify(a: boolean) {
        this._authorizedToModify = a;
    }

    ngOnInit() {

        // TODO get Default value from settings
        this.marginForm = this.fb.group({
            generalExpenses: [12, [Validators.min(0), Validators.max(100)]],
            profit: [20, [Validators.min(0), Validators.max(100)]],
            ciTaxesPercentage: [8.875, [Validators.min(0), Validators.max(100)]],
            capitalImprovement: !!this._budget?.export
        });
        this.marginForm.controls['capitalImprovement'].valueChanges.subscribe(value => {
            if (value) {
                this.marginForm.controls['ciTaxesPercentage'].enable();
            } else {
                this.marginForm.controls['ciTaxesPercentage'].disable();
            }
        });
        this.patchForm();

        /**
         * Auto save / commit enabled
         */
        this.marginForm.valueChanges
            .pipe(debounceTime(1000),
                takeUntil(this.unsubscribe$),
                distinctUntilChanged((val1, val2) => {
                    return val1.generalExpenses === val2.generalExpenses &&
                        val1.profit === val2.profit &&
                        val1.capitalImprovement === val2.capitalImprovement &&
                        val1.ciTaxesPercentage === val2.ciTaxesPercentage;
                }))
            .subscribe(async result => {
                await this.submit();
            });
    }

    patchForm() {

        if (this._budget && this._budget.margin) {

            if (this._budget.margin.generalExpenses !== undefined &&
                this._budget.margin.profit !== undefined &&
                (<MarginV2>this._budget.margin).ciTaxesPercentage !== undefined &&
                (<MarginV2>this._budget.margin).capitalImprovement !== undefined) {
                console.log('margin-form: patch form: ', this._budget);
                this.marginForm.patchValue({
                    generalExpenses: this._budget.margin.generalExpenses,
                    ciTaxesPercentage: (<MarginV2>this._budget.margin).ciTaxesPercentage,
                    profit: this._budget.margin.profit,
                    capitalImprovement: (<MarginV2>this._budget.margin).capitalImprovement
                });
            }
        }

        if (this._budget &&
            (BudgetModel.isEditable(this._budget, this.auth.currentUser) || this.authorizedToModify)) {
            this.marginForm.enable();
        } else {
            this.marginForm.disable();
        }
    }

    checkDisableCiTax(value: boolean) {
        console.log('margin-form: checkDisableCiTax....', value);
        if (value) {
            this.marginForm.controls['ciTaxesPercentage'].enable();
        } else {
            this.marginForm.controls['ciTaxesPercentage'].disable();
        }

    }

    async updateBudget(updateObj: { margin: MarginV2 }) {
        console.log('margin-form: updating budget margin...', updateObj.margin);
        this.formUpdating = true;
        this.marginForm.disable();

        try {
            const patchResult = await this.dataService.patchBudget(this._budget.id, updateObj);
            console.log('margin-form: Budget margin patched', patchResult);
            this.marginForm.enable();
        } catch (err) {
            console.log(err);
            this.checkDisableCiTax(this.marginForm.controls['capitalImprovement'].value);
            this.marginForm.disable();
        } finally {
            this.checkDisableCiTax(this.marginForm.controls['capitalImprovement'].value);
            this.formUpdating = false;
        }
    }

    async submit() {
        const margin: MarginV2 = {
            generalExpenses: this.marginForm.value.generalExpenses,
            ciTaxesPercentage: this.marginForm.getRawValue().ciTaxesPercentage,
            profit: this.marginForm.value.profit,
            capitalImprovement: this.marginForm.value.capitalImprovement
        };
        await this.updateBudget({margin});
        this.updateCostSummary.emit(this._budget.id);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

