<span *ngIf="budget$ | async as budget; else loading">
  <mat-card>
    <div>
      <mat-card-title>
        <a [routerLink]="['/budget',budget.id]">
          <span *ngIf="budget.project">Project {{ budget.project.projectNr }}</span>
          <span *ngIf="!budget.project">Budget {{ budget.budgetNr }}</span>
        </a>
        <span
                *ngIf="budget.prosperworks">&nbsp;{{ budget.prosperworks.name }}&nbsp;<small>({{ budget.prosperworks.company_name }}
            )</small></span>
      </mat-card-title>

      <ul class="summary-meta">
        <li><strong>Status:</strong> {{ budget.status }}</li>
        <li><strong>Budget Creation Date:</strong> {{ budget.creation | timestamp | date }}</li>
        <li *ngIf="budget.project"><strong>Project Delivery Date (Scheduled):</strong>
            {{ budget.project.deliveryDate | timestamp | date }}</li>
        <li *ngIf="budget.project"><strong>Project Archived Date:</strong>
            {{ budget.archivedDate | timestamp | date }}</li>
      </ul>
      <mat-card-content>
        <table>
          <tr *ngIf="budget.project">
            <td><strong>Project Manager:</strong></td>
            <td>{{ budget.project?.projectManager?.displayName }}</td>
          </tr>
          <tr>
            <td><strong>Budget Author:</strong></td>
            <td>{{ budget.assignee?.displayName }}</td>
          </tr>
        </table>
      </mat-card-content>
    </div>
  </mat-card>
  <mat-card style="overflow-x: auto;" *ngIf="summaryBalance$ | async as summary; else loading">
      <app-summary-balance-v1
              *ngIf="budgetId && budget.version === 'v1'"
              [budget]="budget"
              [summary]="summary"
              [budgetId]="budgetId">
    </app-summary-balance-v1>
    <app-summary-balance-v2
            *ngIf="budgetId && budget.version === 'v2'"
            [budget]="budget"
            [summary]="summary"
            [budgetId]="budgetId">
    </app-summary-balance-v2>
  </mat-card>

</span>

<ng-template #loading>
    <mat-card-title>
        Loading budget...
    </mat-card-title>
</ng-template>

<app-activity-balance-table *ngIf="budgetId" [budgetId]="budgetId"></app-activity-balance-table>

<app-materials-balance-table *ngIf="budgetId" [budgetId]="budgetId"></app-materials-balance-table>
