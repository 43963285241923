import {Component, Input, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {SummaryBalanceWrapper} from '../../../budget/interfaces/summaryBalance';



@Component({
    selector: 'app-summary-balance-v1',
    templateUrl: './summary-balance-component-v1.component.html',
    styleUrls: ['./summary-balance-component-v1.component.scss']
})
export class SummaryBalanceComponentV1 implements OnDestroy {

    private unsubscribe$ = new Subject();

    @Input() budgetId;
    @Input() budget;
    @Input() summary: SummaryBalanceWrapper;

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    compare(a: number, b: number) {
        return a > b;
    }

    getGenExpProfitDiff(summary) {
        return Math.abs(summary.costSummary.profit.final - summary.costSummary.profit.budget);
    }

}
