<div class="column">
    <div class="row center-in-row">
        <mat-form-field>
            <input #oreCommesseFromDate [matDatepicker]="oreCommesseFromDatePicker"
                   [value]="oreCommesseFromDateSubject.value" matInput
                   placeholder="From Date">
            <mat-datepicker-toggle [for]="oreCommesseFromDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #oreCommesseFromDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input #oreCommesseToDate [matDatepicker]="oreCommesseToDatePicker"
                   [value]="oreCommesseToDateSubject.value" matInput
                   placeholder="To Date">
            <mat-datepicker-toggle [for]="oreCommesseToDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #oreCommesseToDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="center-in-row" style="margin-left: 10px">
            <button (click)="generateOreCommesseReport()" [disabled]="loading" color="primary" i18n mat-raised-button>
                Generate report commesse/ore
            </button>
        </div>
    </div>


    <mat-card class="full-width no-p mb-1">
        <div class="example-container mat-elevation-z8">

            <mat-table [dataSource]="reportFiles" matSort>

                <ng-container matColumnDef="createdAt">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Created At</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.createdAt|timestamp | date:'long' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="endAt">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>End At</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.endAt|timestamp | date:'long' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.status }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fileReference">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>File</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <a class="is-link" *ngIf="row.fileReference"
                           (click)="openOreCommesseLink(row.id)">Download Report</a>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Type</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.type }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="details">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Details</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.details }}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="reportsColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: reportsColumns;"></mat-row>
            </mat-table>

            <mat-paginator [length]="reportFiles?.length" [pageSizeOptions]="[25, 50, 100, 1000]"></mat-paginator>
        </div>
    </mat-card>

</div>
