import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';



@Component({
    selector: 'app-sidebar-list',
    templateUrl: './sidebar-list.component.html',
    styleUrls: ['./sidebar-list.component.css']
})
export class SidebarListComponent implements OnInit, OnDestroy {

    public userRole = 'user';
    public cosmoEnabled;
    private subscription = new Subscription();

    constructor(private authService: AuthService, private configService: ConfigService) {
    }

    private _canViewBudget: boolean;

    get canViewBudget(): boolean {
        return this._canViewBudget;
    }

    private _canViewOrder;

    get canViewOrder(): boolean {
        return this._canViewOrder;
    }

    private _canViewInvoicesCosmo;

    get canViewInvoicesCosmo(): boolean {
        return this._canViewInvoicesCosmo;
    }

    private _canViewInternalProjects;

    get canViewInternalProjects(): boolean {
        return this._canViewInternalProjects;
    }

    private _canViewTimeReport;

    get canViewTimeReport(): boolean {
        return this._canViewTimeReport;
    }

    private _canViewStatCollector;

    get canViewStatCollector(): boolean {
        return this._canViewStatCollector;
    }

    private _canViewLaserCut;

    get canViewLaserCut(): boolean {
        return this._canViewLaserCut;
    }

    private _canViewUsers;

    get canViewUsers(): boolean {
        return this._canViewUsers;
    }

    private _canViewAdmin;

    get canViewAdmin(): boolean {
        return this._canViewAdmin;
    }

    private _canViewBalanceOverview;

    get canViewBalanceOverview(): boolean {
        return this._canViewBalanceOverview;
    }

    ngOnInit() {

        this.subscription.add(this.authService.userStream$.subscribe((val) => {
            if (val) this.userRole = val.role;
        }));

        this.subscription.add(this.authService.userHasPermission('app.budget').subscribe((val) => {
            this._canViewBudget = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.order').subscribe((val) => {
            this._canViewOrder = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.invoices.cosmo').subscribe((val) => {
            this._canViewInvoicesCosmo = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.iproject').subscribe((val) => {
            this._canViewInternalProjects = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.lasercut').subscribe((val) => {
            this._canViewLaserCut = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.users').subscribe((val) => {
            this._canViewUsers = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.admin').subscribe((val) => {
            this._canViewAdmin = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.timereport').subscribe((val) => {
            this._canViewTimeReport = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.reportactivities').subscribe((val) => {
            this._canViewStatCollector = val;
        }));

        this.subscription.add(this.authService.userHasPermission('app.balanceoverview').subscribe((val) => {
            this._canViewBalanceOverview = val;
        }));

        this.configService.settings.subscribe((sett) => {
            if (sett) {

                try {
                    this.cosmoEnabled = sett.value.cosmo.enabled;
                } catch (err) {
                    //
                }
            }
        });

    }

    ngOnDestroy() {

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
